
import { defineComponent, nextTick } from 'vue'
import FileInput from '@/components/forms/FileInput.vue'
import { Infos } from '@/types/portal'
import ApiService from '@/services/ApiService'
import { AdmissibleInfos } from '@/types/layout'
import { useStore } from 'vuex'
import BasicCard from '@/components/cards/BasicCard.vue'
import dateFormat from 'dateformat'

export default defineComponent({
  name: 'AdmissibleCv',
  components: { BasicCard, FileInput },
  mounted () {
    nextTick(() => {
      ApiService.get<{infos: Infos}>('/admissibles/portal/infos').then((response) => {
        this.infos = response.data.infos
      }).then(() => {
        nextTick(() => {
          if (this.$route.hash) {
            const anchor = document.getElementById(this.$route.hash.substring(1))
            anchor?.classList.add('card-anchor-focus')
            anchor?.scrollIntoView({
              behavior: 'smooth'
            })
          }
        })
      })
    })
  },
  data () {
    return {
      infos: {} as Infos,
      isSubmitting: false,
      uploadedFiles: {} as {[key: string]: File},
      uploadErrors: {} as {[key: string]: string}
    }
  },
  methods: {
    handleFileUpload (documentName: string, event: Event) {
      const files = (event.target as HTMLInputElement)?.files
      if (files) {
        this.uploadedFiles[documentName] = files[0]
      }
    },

    submitFiles () {
      this.isSubmitting = true
      ApiService.post<{[key: string]: File}, { infos: Infos }>('/admissibles/portal/upload', this.uploadedFiles, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => {
        this.infos = response.data.infos
        this.uploadedFiles = {}
        this.uploadErrors = {}
      }).catch((error) => {
        this.uploadErrors = error.response.data.errors
      }).finally(() => {
        this.isSubmitting = false
      })
    },

    download (event: Event, url: string) {
      ApiService.get<string>(url, { responseType: 'blob' }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
        window.open(url)
      })

      event.stopPropagation()
      event.preventDefault()
    },

    formatDate (date: string) {
      return dateFormat(date, 'dd mmmm yyyy HH:MM')
    }
  },
  computed: {
    admissibleInfos (): AdmissibleInfos {
      return useStore().getters.getAdmissibleInfos
    }
  }
})
